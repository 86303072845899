<template>
  <div>
    <div>
      <create-nurse fetchClinicsBy="superAdmin"
        :franchiseId ="franchiseId"
       v-if="franchiseId"/>

    </div>
  </div>
</template>

<script>
  import CreateNurse from "../../components/nurses/createNurse";

  export default {
    name: "createFranchiseNurse",
    components: {
      "create-nurse": CreateNurse
    },
    data() {
      return {
        isDisabled: true,
        franchiseId:'',
        flashMessage: "",
        showDismissibleAlert: false,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirm_password: "",
        isTermsConditionAccepted: false,
        userData: {
          status: "Active",
          address: { displayAddress: "" },
          preferredName: "",
          contactNumber: "",
          ahpraRegistrationNumber: "",
          ahpraExiryDate: "",
          indemnityInsuranceProvider: "",
          indemnityInsuranceNumber: "",
          indemnityInsuranceExpiry: "",
          ahpraCertificate: "",
          insuranceCertificate: "",
          profileImage: "",
          nurseCosmeticExperience: ""
        }
      };
    },
    methods: {
      async registerUserJWtt() {
        let payload = {};
        payload = { ...this.userData };
        payload.firstname = this.firstName;
        const valid = await this.$refs.stepTwoRef.$validator.validate();
      },
      async registerUserJWt() {
        let self = this;
        // If form is not validated or user is already login return
        // if (!this.validateForm || !this.checkLogin()) return
        let flag = false;
        const nameValid = await this.$validator.validate();
        if (!nameValid) {
          flag = true;
        }
        const valid = await this.$refs.stepTwoRef.$validator.validate();

        if (!valid) {
          flag = true;
        }
        if (this.userData.ahpraCertificate == "") {
          this.$refs.stepTwoRef.errors.add({
            field: "aphraCertificate",
            msg: "APHRA Certificate is required"
          });
          flag = true;
        } else {
          this.$refs.stepTwoRef.errors.remove("aphraCertificate");
        }

        if (this.userData.insuranceCertificate == "") {
          this.$refs.stepTwoRef.errors.add({
            field: "insuranceCertificate",
            msg: "Insurance Certificate is required"
          });
          flag = true;
        } else {
          this.$refs.stepTwoRef.errors.remove("insuranceCertificate");
        }
        if (flag) return false;

        let payload = {};
        let userInfo = JSON.parse(
          localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
        );
        let userId = "";
        if (userInfo && userInfo._id) userId = userInfo._id;
        payload = {
          userDetails: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            password: this.password,
            confirmPassword: this.confirm_password,
            userType: "nurse"
          },
          notify: this.$vs.notify
        };
        payload.userDetails = { ...payload.userDetails, ...this.userData };
        this.$store
          .dispatch("franchise/registerFranchiseNurse", payload)
          .then(res => {
            this.$vs.notify({
              title: "Nurse Registration",
              text: "Nurse Registered Successfully.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
          })
          .catch(err => {
            this.$vs.notify({
              title: "Register Attempt",
              text: err.response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          });
      }
    },
    computed: {
      validateForm() {
        return this.isTermsConditionAccepted === true;
      }
    },
    mounted() {
      let userInfo = JSON.parse(localStorage.getItem("user"));
    },
    created() {
      this.franchiseId = this.$route.params.franchiseId;

    }
  }
</script>

<style scoped>

</style>
